import Head from 'next/head';

import { useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '@store/auth/auth.actions';
import { selectIsAuthenticated } from '@store/auth/auth.slice';

import useAppModuleConfig from '@hooks/useAppModuleConfig';
import { isClient } from '@utils/helpers';

const SalesManagoScript = ({ clientId, endpoint }) => {
  const dispatch = useDispatch();

  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { enabled, termsSupportType } = useAppModuleConfig('SalesManago');
  const isSalesManagoCookiebotActive =
    enabled && termsSupportType === 'COOKIEBOT';

  const cookiebot = isClient ? window.Cookiebot : undefined;
  const isCookieConsentMarketingEnabled = () =>
    cookiebot?.consent?.marketing || false;

  useEffect(() => {
    if (isSalesManagoCookiebotActive && cookiebot) {
      const syncSalesManago = () => {
        if (isAuthenticated) {
          dispatch(
            updateUserData({
              cookiebotMarketingTerms: isCookieConsentMarketingEnabled(),
            })
          );
        }

        forceUpdate();
      };

      syncSalesManago();

      window.addEventListener('CookiebotOnAccept', syncSalesManago);
      window.addEventListener('CookiebotOnDecline', syncSalesManago);

      return () => {
        window.removeEventListener('CookiebotOnAccept', syncSalesManago);
        window.removeEventListener('CookiebotOnDecline', syncSalesManago);
      };
    }
  }, [isAuthenticated]);

  if (
    !clientId ||
    !endpoint ||
    (isSalesManagoCookiebotActive && !isCookieConsentMarketingEnabled())
  ) {
    return null;
  }

  return (
    <Head>
      <script
        id="salesmanago-script"
        dangerouslySetInnerHTML={{
          __html: `
          var _smid = "${clientId}";
          (function(w, r, a, sm, s ) {
            w['SalesmanagoObject'] = r;
            w[r] = w[r] || function () {( w[r].q = w[r].q || [] ).push(arguments)};
            sm = document.createElement('script');
            sm.type = 'text/javascript';
            sm.async = true;
            sm.src = a;
            s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(sm, s);
          })(window, 'sm', ('https:' == document.location.protocol ? 'https://' : 'http://') + '${endpoint}/static/sm.js');`,
        }}
      />
    </Head>
  );
};

export default SalesManagoScript;
